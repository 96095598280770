// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import SignUp from './auth/SignUp';
import ConfirmRegistration from './auth/ConfirmRegistration';
import LoginUser from './auth/LoginUser';
import HomePage from './auth/HomePage';
import Account from './auth/Account';
import PrivateRoute from './components/PrivateRoute';
import Logout from './auth/Logout';
import { CssBaseline, Container } from '@mui/material';
import Navbar from './components/Navbar';
import BottomNav from './components/BottomNav';
import Home from './pages/Home';
import NewsPage from './pages/NewsPage';
import BlogPage from './pages/BlogPage';
import Coupons from './pages/Coupons';
import Spacer from './components/Spacer';
import { Helmet } from 'react-helmet';
import { TitleProvider, useTitle } from './TitleContext';
import AWS from 'aws-sdk';
import { poolData, identityPoolId } from './awsConfig';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';

const HelmetTitle = () => {
  const defaultTitle = useTitle();
  return (
    <Helmet>
      <title>{defaultTitle}</title>
    </Helmet>
  );
};

const AppContent = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  // const location = useLocation();

  useEffect(() => {
    const idToken = localStorage.getItem('idToken');
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    const email = localStorage.getItem('email')?.toLowerCase();

    if (idToken && accessToken && refreshToken && email) {
      AWS.config.update({
        region: 'ap-northeast-1',
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: identityPoolId,
          Logins: {
            [`cognito-idp.ap-northeast-1.amazonaws.com/${poolData.UserPoolId}`]: idToken,
          },
        }),
      });

      const userPool = new CognitoUserPool(poolData);
      const userData = { Username: email, Pool: userPool };
      const cognitoUser = new CognitoUser(userData);

      cognitoUser.getSession((err, session) => {
        if (err) {
          console.error('Session retrieval failed:', err);
        } else {
          cognitoUser.refreshSession(session.getRefreshToken(), (err, session) => {
            if (err) {
              console.error('Token refresh failed:', err);
            } else {
              localStorage.setItem('accessToken', session.getAccessToken().getJwtToken());
              localStorage.setItem('idToken', session.getIdToken().getJwtToken());
              setUser({ email });
              // navigate('/account'); // 強制リダイレクトを削除
            }
          });
        }
      });
    }
  }, [navigate]);

  return (
    <>
      <HelmetTitle />
      <CssBaseline />
      <div className="app-container">
        <Navbar />
        <div className="content">
          <Spacer height="64px" /> {/* AppBarの高さ分のスペース */}
          <Container>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/auth-home" element={<HomePage />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/confirm-registration" element={<ConfirmRegistration />} />
              <Route path="/login" element={<LoginUser setUser={setUser} />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/news" element={<NewsPage />} />
              <Route path="/blog" element={<BlogPage />} />
              <Route element={<PrivateRoute />}>
                <Route path="/account" element={<Account user={user} />} />
                <Route path="/coupons" element={<Coupons />} />
              </Route>
            </Routes>
          </Container>
          <Spacer height="56px" /> {/* BottomNavの高さ分のスペース */}
        </div>
        <BottomNav />
      </div>
    </>
  );
};

const App = () => (
  <Router>
    <TitleProvider>
        <AppContent />
    </TitleProvider>
  </Router>
);

export default App;
