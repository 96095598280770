// TitleContext.js
import React, { createContext, useContext } from 'react';

const TitleContext = createContext();

export const useTitle = () => {
  return useContext(TitleContext);
};

export const TitleProvider = ({ children }) => {
  const defaultTitle = 'YS CIT';
  return (
    <TitleContext.Provider value={defaultTitle}>
      {children}
    </TitleContext.Provider>
  );
};
