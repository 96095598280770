import React from 'react';
import News from '../components/News';
import { Helmet } from 'react-helmet';
import { useTitle } from '../TitleContext';

const Home = () => {
  const defaultTitle = useTitle();
  return (
    <div className="content">
      <Helmet>
        <title>{`${defaultTitle} - ホームページ`}</title>
      </Helmet>
      <h1>TOPページ</h1>
      <p>開錠確認をテストするためのサイトです。</p>
      <div className="card">
        <News />
      </div>
    </div>
  );
};

export default Home;
