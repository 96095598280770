import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AWS from 'aws-sdk';
import { Container, TextField, Button, Typography, Box, CircularProgress } from '@mui/material';
import { useLocation } from 'react-router-dom'; // URLからクエリパラメータを取得するために使用
import { identityPoolId } from '../awsConfig';

// URLクエリパラメータを取得するヘルパー関数
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ConfirmRegistration = () => {
  const query = useQuery();
  const [userId, setUserId] = useState(query.get('userId') || '');
  const [tempKey, setTempKey] = useState(query.get('tempKey') || '');
  const [storeName, setStoreName] = useState(query.get('storeName') || '');
  const [storeUserId, setStoreUserId] = useState('');
  const [loggedInStoreName, setLoggedInStoreName] = useState('');
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      console.error('Access Tokenが見つかりません');
      return;
    }

    console.log('Access Token:', accessToken); // デバッグ用ログ

    AWS.config.update({
      region: 'ap-northeast-1',
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: identityPoolId,
      }),
    });

    const cognitoISP = new AWS.CognitoIdentityServiceProvider();
    const params = {
      AccessToken: accessToken,
    };

    let isMounted = true;

    const fetchStoreData = async () => {
      try {
        const data = await cognitoISP.getUser(params).promise();
        if (isMounted) {
          console.log('Store data:', data); // デバッグ用ログ

          const storeIdAttribute = data.UserAttributes.find(attr => attr.Name === 'sub');
          setStoreUserId(storeIdAttribute ? storeIdAttribute.Value : '');
          setLoggedInStoreName(data.Username);  // Usernameを取得してストアネームとして使用
        }
      } catch (error) {
        console.error('店舗情報の取得に失敗しました:', error);
      }
    };

    fetchStoreData();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleConfirmRegistration = async () => {
    if (storeName !== loggedInStoreName) {
      alert('入力された店舗名がログイン中の店舗名と一致しません。');
      return;
    }

    setLoading(true);
    setResponseMessage(null);
    setError(null);

    try {
      const requestBody = {
        store_name: storeName,
        store_user_id: storeUserId,
        temp_key: tempKey,
        user_id: userId
      };

      console.log('Request Body:', requestBody); // デバッグ用ログ

      const response = await axios.post('https://sm97mtuae2.execute-api.ap-northeast-1.amazonaws.com/prod/setup', 
        JSON.stringify(requestBody), {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('accessToken')
        }
      });

      console.log('API response:', response); // APIレスポンスをコンソールに表示

      // レスポンスボディをJSONとして解析
      const responseData = JSON.parse(response.data.body);

      console.log('Parsed response data:', responseData); // パースしたデータをログに出力

      if (responseData.status === 'success') {
        setResponseMessage('認証成功　開錠しました');
      } else {
        setError(`認証失敗: ${responseData.message || '不明なエラー'} ${responseData.status || '不明なステータス'}`);
      }
    } catch (error) {
      console.error('認証に失敗しました:', error);
      setError('認証に失敗しました');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ textAlign: 'center', mt: 5 }}>
        <Typography variant="h4">KitonSite 認証画面</Typography>
        <Typography variant="body1" mt={2}>QRコードの内容を入力してください。</Typography>
        <Box mt={2}>
          <TextField fullWidth label="User ID" value={userId} onChange={(e) => setUserId(e.target.value)} margin="normal"/>
        </Box>
        <Box mt={2}>
          <TextField fullWidth label="Temp Key" value={tempKey} onChange={(e) => setTempKey(e.target.value)} margin="normal"/>
        </Box>
        <Box mt={2}>
          <TextField fullWidth label="Store Name" value={storeName} onChange={(e) => setStoreName(e.target.value)} margin="normal"/>
        </Box>
        <Box mt={2}>
          <Button variant="contained" color="primary" onClick={handleConfirmRegistration} disabled={loading}>
            {loading ? <CircularProgress size={24} /> : '送信'}
          </Button>
        </Box>
        {loading && <Typography variant="body1" mt={2}>申請中...</Typography>}
        {responseMessage && <Typography variant="body1" mt={2} color="green">{responseMessage}</Typography>}
        {error && <Typography variant="body1" mt={2} color="red">{error}</Typography>}
      </Box>
    </Container>
  );
};

export default ConfirmRegistration;
